import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function FullWidthImage(props) {
  const {
    height = 500,
    img,
    title,
    subheading,
    imgPosition = '50% 50%',
  } = props;

  return (
    <React.Fragment>
      <div
        className="margin-top-0"
        style={{
          display: 'grid',
          alignItems: 'center',
        }}
      >
        {img?.url ? (
          <img
            src={img.url}
            objectFit="cover"
            objectPosition={imgPosition}
            style={{
              gridArea: '1/1',
              // You can set a maximum height for the image, if you wish.
              height: height,
              width: '100%',
            }}
            // You can optionally force an aspect ratio for the generated image
            aspectratio={3 / 1}
            // This is a presentational image, so the alt should be an empty string
            alt=""
            formats={['auto', 'webp', 'avif']}
          />
        ) : (
          <GatsbyImage
            image={img}
            objectFit="cover"
            objectPosition={imgPosition}
            style={{
              gridArea: '1/1',
              // You can set a maximum height for the image, if you wish.
              maxHeight: height,
            }}
            layout="fullWidth"
            // You can optionally force an aspect ratio for the generated image
            aspectratio={3 / 1}
            // This is a presentational image, so the alt should be an empty string
            alt=""
            formats={['auto', 'webp', 'avif']}
          />
        )}
        <div
          style={{
            // By using the same grid area for both, they are stacked on top of each other
            gridArea: '1/1',
            position: 'relative',
            // This centers the other elements inside the hero component
            placeItems: 'center',
            display: 'grid',
          }}
        >
          {/* Any content here will be centered in the component */}
          {title && (
            <h1
              className="font-bold text-xl sm:text-2xl md:text-4xl"
              style={{
                boxShadow: '#BD0D1E 0.5rem 0px 0px, #BD0D1E -0.5rem 0px 0px',
                backgroundColor: '#BD0D1E',
                color: 'white',
                lineHeight: '1',
                padding: '0.25em',
              }}
            >
              {title}
            </h1>
          )}
          {/* <h3
            className="text-bold text-md sm:text-lg md:text-2xl"
            style={{
              boxShadow: '#BD0D1E 0.5rem 0px 0px, #BD0D1E -0.5rem 0px 0px',
              backgroundColor: '#BD0D1E',
              color: 'white',
              lineHeight: '1',
              padding: '0.25rem',
              marginTop: '0.5rem',
              marginLeft: '1rem',
              marginRight: '1rem',
            }}
          >
            In life, there are those who make excuses and those who get it done.
            We only live once &#8212; get it done.
          </h3> */}
        </div>
      </div>
    </React.Fragment>
  );
}

FullWidthImage.propTypes = {
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  height: PropTypes.number,
  subheading: PropTypes.string,
};
